
import omit from 'lodash/omit'
import some from 'lodash/some'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'

import request from 'superagent';

import React, {useState} from 'react'

import SwipeableViews from "react-swipeable-views";

import makeStyles from '@material-ui/core/styles/makeStyles';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import TicketList from './TicketList'
import TicketHolderForm from './TicketHolderForm'
import SelectPaymentMethodForm from './SelectPaymentMethodForm'

import logo from './assets/images/notv-logo.png';

import {formatCurrency} from './currency'
import {goodReply, extractReply} from './lib/response'

const useStyles = makeStyles(theme => ({
  contaner: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    borderTop: '1px solid rgb(200, 200, 200)',
    boxSizing: 'border-box',
  },
  list: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: 100,
      marginRight: 100
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 60,
      marginRight: 60
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 60,
      marginRight: 60
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 8,
      marginRight: 8
    },
    paddingTop: 16,
    paddingBottom: 16,
  },
  progressContainer: {
    position: 'absolute'
  },
  progress: {
  },
  inlineLogo: {
    width: 64,
    height: 64
  },
}))

const createPurchase = async (eventId, purchase, params = {}) => {

  const res = await request.post(`/api/v1/e/${eventId}/purchases`)
    .send({
      pc: params.pc,
      ...purchase
    }) 
    .ok(goodReply)
    .then(extractReply)

  if(res.status !== 200 || !res.body.success) {
    throw new Error('Oops, something went wrong!')
  }

  return res.body.data
}

const loadEventPaymentMethods = async (eventId, params = {}) => {

  const res = await request.get(`/api/v1/e/${eventId}/payment-methods`)
    .query({pc: params.pc})
    .ok(res => true)
    .then(extractReply)

  if(res.status !== 200 || !res.body.success) {
    throw new Error('Oops, something went wrong!')
  }

  return res.body.data
}

const startPayment = async (purchaseId, payment_method, issuer) => {

  const res = await request.post(`/api/v1/purchase/${purchaseId}/start-payment`)
    .send({
      payment_method,
      issuer
    }) 
    .ok(goodReply)
    .then(extractReply)

  if(res.status !== 200 || !res.body.success) {
    throw new Error('Oops, something went wrong!')
  }

  return res.body.data
}

const BuyTicketDialogContent = props => {

  const classes = useStyles()

  const {params, countries, event, tickets, activeStep, setActiveStep, errors, setErrors} = props

  const [inProgress, setInProgress] = useState(false)
  const [items, setItems] = useState({})
  const [ticketHolder, setTicketHolder] = useState({country: 'nl'})
  const [paymentMethods, setPaymentMethods] = useState()
  const [purchase, setPurchase] = useState()
  const [paymentMethod, setPaymentMethod] = useState()
  const [issuer, setIssuer] = useState()

  const calculateTotal = () => {
    let t = 0;

    forEach(items, (quantity, ticked_id) => {
      const ticket = find(tickets, ticket => ticket._id === ticked_id)
      if(ticket) {
        t += ticket.price * quantity
      }
    })

    return t
  }

  const calculateTotalQuantity = () => {
    let t = 0;

    forEach(items, (quantity, ticked_id) => {
      const ticket = find(tickets, ticket => ticket._id === ticked_id)
      if(ticket) {
        t += quantity
      }
    })

    return t
  }

  const hasFreeTicket = () => {
    return some(items, (quantity, ticked_id) => {
      const ticket = find(tickets, ticket => ticket._id === ticked_id)
      return ticket && ticket.type === 'free'
    })
  }

  const handleChangeItems = (ticket_id, newQuantity) => {
    const newItems = omit(items, [ticket_id])

    if(newQuantity > 0) {
      newItems[ticket_id] = newQuantity
    }

    setItems(newItems)
  }

  const handleSelectQuantity = () => {
    if(calculateTotalQuantity() > 0) {

      if(hasFreeTicket()) {
        setActiveStep(1)
        return;
      }

      setInProgress(true)

      loadEventPaymentMethods(event._id, params)
        .then(payment_methods => {
          setInProgress(false)
          setPaymentMethods(payment_methods)

          if(!isEmpty(payment_methods)) {
            handleChangePaymentMethod(payment_methods, payment_methods[0].type)
          }

          setActiveStep(1)
        })
        .catch(err => {
          setInProgress(false)
          alert(err.message)
        })
    }
  }

  const handleChangeTicketHolder = (field, value) => {
    setTicketHolder({
      ...ticketHolder,
      [field]: value
    })
  }

  const handleFocus = field => {
    setErrors(omit(errors, [field]))
  }

  const validate = values => {

    const newErrors = {}

    if(!values.first_name) {
      newErrors.first_name = 'This field is required'
    }

    if(!values.last_name) {
      newErrors.last_name = 'This field is required'
    }

    if(!values.email) {
      newErrors.email = 'This field is required'
    }

    if(!values.country) {
      newErrors.country = 'This field is required'
    }

    if(!countries[values.country]) {
      newErrors.country = 'Invalid country'
    }

    return newErrors
  }

  const handleOrder = () => {

    const errors = validate(ticketHolder)

    if(!isEmpty(errors)) {
      setErrors(errors)
      return;
    }

    setInProgress(true)

    createPurchase(event._id, {...ticketHolder, items}, params)
      .then(purchase => {
        setInProgress(false)

        if(!purchase.redirect_url) {
          setPurchase(purchase)
          setActiveStep(2)
        }
        else {
          window.location.assign(purchase.redirect_url);
        }
      })
      .catch(err => {
        setInProgress(false)
        alert(err.message)
      })
  }

  const handleChangePaymentMethod = (pms, newPaymentMethodType) => {

    const pm = find(pms, paymentMethod => paymentMethod.type === newPaymentMethodType)

    if(pm) {
      if(pm.issuers && !isEmpty(pm.issuers)) {
        setIssuer(pm.issuers[0].id)
      }

      setPaymentMethod(newPaymentMethodType)
    }
  }

  const handleStartPayment = () => {

    setInProgress(true)

    startPayment(purchase.id, paymentMethod, issuer)
      .then(res => {
        window.location.assign(res.payment_url);
      })
      .catch(err => {
        alert(err.message)
      })
      .finally(() => setInProgress(false))
  }

  const total = calculateTotal();

  return (
    <Grid container
      direction="column"
      className={classes.contaner}
    >
      <Grid item>
        <SwipeableViews index={activeStep} containerStyle={{height: 400}}>
          <TicketList tickets={tickets} order={items} onChange={handleChangeItems} />
          <TicketHolderForm
            event={event}
            countries={countries}
            tickets={tickets}
            values={ticketHolder}
            errors={errors}
            onChange={handleChangeTicketHolder}
            onFocus={handleFocus}
          />
          <SelectPaymentMethodForm
            paymentMethods={paymentMethods}
            paymentMethod={paymentMethod}
            issuer={issuer}
            onChangePaymentMethod={value => {
              handleChangePaymentMethod(paymentMethods, value)
            }}
            onChangeIssuer={setIssuer}
          />
        </SwipeableViews>
      </Grid>
      <Grid item style={{borderTop: '1px solid rgb(200, 200, 200)'}}>
        <div className={classes.list}>
          <Grid container justify="space-between" style={{marginBottom: 20, borderBottom: '1px solid rgb(200, 200, 200)'}}>
            <Grid item>
              <img src={logo} className={classes.inlineLogo} alt="notv" />
            </Grid>
            <Grid item>
              <Typography>After completing your ticket purchase you will receive a confirmation email with a link to the event. <span style={{fontWeight: 'bold'}}>Always use the link to access the event video!</span></Typography>
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <Grid item>
              {total > 0 && <Typography><span style={{fontWeight: 'bold', fontSize: '1.2em'}}>{`Order total: ${formatCurrency(total)}`}</span></Typography>}
            </Grid>
            <Grid item>
              {activeStep === 0 && <Button
                variant="contained"
                disabled={total === 0 || inProgress}
                onClick={handleSelectQuantity}
              >
                Proceed
                {inProgress && <div className={classes.progressContainer}>
                  <CircularProgress className={classes.progress} size={18} />
                </div>}
              </Button>}
              {activeStep === 1 && <Button
                variant="contained"
                disabled={total === 0 || inProgress}
                onClick={handleOrder}
              >
                {hasFreeTicket() ? 'Confirm' : 'Proceed'}
                {inProgress && <div className={classes.progressContainer}>
                  <CircularProgress className={classes.progress} size={18} />
                </div>}
              </Button>}
              {activeStep === 2 && <Button
                variant="contained"
                disabled={inProgress}
                onClick={handleStartPayment}
              >
                Proceed
                {inProgress && <div className={classes.progressContainer}>
                  <CircularProgress className={classes.progress} size={18} />
                </div>}
              </Button>}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}

export default BuyTicketDialogContent
