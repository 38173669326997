
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MaterialTextField from '@material-ui/core/TextField';
import MaterialTooltip from '@material-ui/core/Tooltip';

import withStyles from '@material-ui/core/styles/withStyles';

import themeStyles from './TextField.theme.style';

class TextField extends Component {

  state = {
    arrowRef: null,
  };

  handleArrowRef = node => {
    this.setState({
      arrowRef: node,
    });
  };

  render() {
    const {
      classes,
      label,
      error,
      helperText,
      ...custom
    } = this.props

    return (
      <MaterialTooltip
        classes={{tooltip: classes.errorTooltip, popper: classes.arrowPopper}}
        open={error}
        title={
          <React.Fragment>
            {helperText || '<undefined>'}
            <span className={classes.arrowArrow} ref={this.handleArrowRef} />
          </React.Fragment>
        }
        PopperProps={{
          popperOptions: {
            modifiers: {
              arrow: {
                enabled: Boolean(this.state.arrowRef),
                element: this.state.arrowRef,
              }
            }
          }
        }}
      >
        <MaterialTextField
          fullWidth
          label={label}
          error={error}
          {...custom}
        />
      </MaterialTooltip>
    )
  }

}

TextField.propTypes = {
  classes: PropTypes.object.isRequired,
};

TextField = withStyles(themeStyles, { withTheme: true })(TextField)

export default TextField;
