
import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(theme => ({
  cancelButton: {
    color: theme.palette.success.main,
  },
  proceedButton: {
    color: theme.palette.getContrastText(theme.palette.success.main),
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
  progressContainer: {
    position: 'absolute'
  },
  progress: {
    color: theme.palette.getContrastText(theme.palette.error.main)
  }
}));

const Transition = props => <Slide direction="up" {...props} />

const RequestPermissionDialog = props =>  {

  const classes = useStyles();

  const {open, title, subtitle, buttontitle, onClose, onProceed} = props

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={onClose}
      aria-labelledby="request-permission-dialog-title"
    >
      <DialogTitle id="request-permission-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {subtitle}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onProceed}
          color="primary"
          variant="contained"
          classes={{root: classes.proceedButton}}
        >
          {buttontitle || 'Proceed'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RequestPermissionDialog;

