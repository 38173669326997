/*
import request from 'superagent';
*/

import React/*, {useState, useEffect}*/ from 'react'

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  imagePlaceholder: {
    width: '100%',
    height: '100%',
    maxHeight: 650,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    maxHeight: 650,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  image: {
    objectFit: 'contain',
    objectPosition: '50% 50%',
    borderRadius: 0,
    backgroundColor: 'rgba(0, 0, 0, 1.0)',
  }
}))

const ImagePreview = props => {

  const classes = useStyles()

  const {contentItem, cdnUrl = 'https://sndwr.ams3.cdn.digitaloceanspaces.com'} = props

  const url = cdnUrl + '/' + contentItem.key

/*
  const [image, setImage] = useState()

  useEffect(() => {
    if(!image) {
      request
        .get(url)
        .responseType('blob')
        .then(res => {
          setImage(res.body)
        })
        .catch(console.log)
    }
  }, [])
*/

/*
  if(!image) {
    return (<div className={classes.imagePlaceholder} />)
  }
*/

  return (
    <div className={classes.imageContainer}>
      <img
        className={classes.image}
        src={url}
        alt={contentItem.name}
        width="100%"
        height="100%"
      />
    </div>
  )
}

export default ImagePreview
