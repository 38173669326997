
import React from 'react'

import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  mainHeader: {
    position: 'relative',
    left: 0,
    right: 0,
    textAlign: 'center',
    zIndex: 99,
    background: 'rgba(20, 20, 20, 0.5)'
  },
  brand: {
    padding: 10
  }
});

const Navbar = props => {

  const {classes} = props

  return (
    <header className={classes.mainHeader}>
      <Grid container>
        <Grid item container sm={12}>
          <a className={classes.brand} href="/">
            <div style={{width: 250, height: 62}} />
          </a>
        </Grid>
      </Grid>
    </header>
  );
}

export default withStyles(styles)(Navbar);
