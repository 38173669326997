
export const goodReply = res => {
  return res.status < 400
}

export const extractReply = res => {

  if(res.status >= 400 || !res.body) {
    throw new Error('Oops, something went wrong!');
  }

  if(!res.body.success) {
    const {message} = res.body
    throw new Error(message || 'Unknown error')
  }

  return res
}
