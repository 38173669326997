
import map from 'lodash/map'

import React from 'react'

import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const SelectPaymentMethodForm = props => {

  const {paymentMethods, paymentMethod, issuer, onChangePaymentMethod, onChangeIssuer} = props

  return (
    <Grid container direction="row" style={{padding: 4}}>
      <Grid item xs={12} style={{padding: 4}}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Select payment method</FormLabel>
          <RadioGroup
            aria-label="Select payment method"
            name="select-payment-method-radio-group"
            value={paymentMethod || ''}
            onChange={(event, value) => onChangePaymentMethod(value)}
          >
            {map(paymentMethods, pm => (
              <React.Fragment>
                <FormControlLabel
                  value={pm.type}
                  control={<Radio />}
                  label={pm.name}
                />
                {pm.issuers && pm.type === paymentMethod && <TextField
                    id="select-issuer"
                    variant="outlined"
                    select
                    value={issuer || ''}
                    onChange={event => onChangeIssuer(event.target.value)}
                  >
                    {map(pm.issuers, issuer => (
                      <MenuItem key={issuer.id} value={issuer.id}>
                        {issuer.name}
                      </MenuItem>
                    ))}
                  </TextField>
                }
              </React.Fragment>
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default SelectPaymentMethodForm
