
import includes from 'lodash/includes'

import request from 'superagent';
import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';

import { useCookies } from 'react-cookie';
import ReactMarkdown from 'react-markdown';
import RemarkBreaks from 'remark-breaks'

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Navbar from './Navbar';
import ContentPreviewWrapper from './ContentPreviewWrapper';
import Footer from './Footer';
import BuyTicketDialog from './BuyTicketDialog'
import VideoPlayer from './VideoPlayer'

import {stringifyEventStartTime} from './lib/time'
import {goodReply, extractReply} from './lib/response'

import {OBJECT_ID_REGEXP} from './constants'

const EVENT_PATH_REGEXP = new RegExp('^/ev/([0-9a-fA-F]+)$', 'i')

const useStyles = makeStyles(theme => ({
  '@global': {
    a: {
      textDecoration: 'none'
    },
    small: {
      fontWeight: 400,
      fontSize: 14
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
      margin: 0,
      lineHeight: '1.2',
      color: 'white'
    }
  },
  mainContent: {
    paddingTop: 25
  },
  title: {
    lineHeight: '1.5',
    margin: 0,
    fontSize: 35,
    backgroundColor: 'white',
    backgroundRepeat: 'repeat-x',
    backgroundPosition: '100% 100%',
    color: 'transparent',
    backgroundClip: 'text',
    '-webkit-font-smoothing': 'antialiased',
    '-webkit-background-clip': 'text',
    '-moz-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent'
  },
  gBorder: {
    borderImageSource: 'linear-gradient(to left, rgba(209, 208, 207, 0), rgba(209, 208, 207, 0.6), rgba(209, 208, 207, 0))'
  },
  streamingHeadContainer: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)'
  },
  streamingDetailsContainer: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)'
  },
  streamingStatus: {
    lineHeight: 2.5
  },
  trendingSub: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '0.7px',
    color: 'white',
    marginBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    '& span': {
      fontSize: 28,
      fontWeight: 500
    }
  },
  trendingYear: {
    paddingLeft: 0,
    fontSize: 24
  },
  description: {
    width: '100%',
    marginBottom: 0,
    marginTop: 0,
    boxSizing: 'border-box',
    display: 'inline-block'
  },
  eventURL: {
    color: 'gray',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  buyTicketButton: {
    [theme.breakpoints.up('md')]: {
      minWidth: 250,
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 200
    },
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: 650
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
    minHeight: 650
  },
  containerFluid: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',

    [theme.breakpoints.up('md')]: {
      paddingLeft: 100,
      paddingRight: 100
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 30,
      paddingRight: 30
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 15,
      paddingRight: 15
    },
    boxSizing: 'border-box'
  }
}));

const LoadingPlaceholder = () => {

  const classes = useStyles()

  return (
    <Grid container className={classes.progressContainer}>
      <Grid item>
        <CircularProgress size={80} style={{color: 'white'}} />
      </Grid>
    </Grid>
  )
}

const ErrorView = props => {

  const {error} = props

  const classes = useStyles()

  return (
    <Grid container className={classes.errorContainer}>
      <Grid item>
        <Typography style={{color: 'red'}}>{error.message || 'An error occurred'}</Typography>
        {error.message === 'Event not found' && <Typography variant="caption" style={{color: 'red'}}>(The event is NOT YET PUBLISHED or the organizer might have removed it from the public view...)</Typography>}
      </Grid>
    </Grid>
  )
}

const extractEventId = async (path) => {

  const res = EVENT_PATH_REGEXP.exec(path)

  if(!res) {
    return path
  }

  const eventId = res[1]

  if(!OBJECT_ID_REGEXP.test(eventId)) {
    throw new Error('Event not found')
  }

  return eventId
}

const getEvent = async (eventId, params) => {

  const res = await request.get(`/api/v1/e`)
    .query({id: eventId, pc: params.pc})
    .ok(goodReply)
    .then(extractReply)

  if(res.status === 404) {
    throw new Error('Event not found')
  }

  if(res.status !== 200 || !res.body.success) {
    throw new Error('Oops, something went wrong!')
  }

  return {event: res.body.data, device_id: res.body.device_id}
}

const EventView = props => {

  const classes = useStyles()

  const {path, params} = props

  const [cookies, setCookie] = useCookies(['did']);

  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState()
  const [event, setEvent] = useState()
  const [now, setNow] = useState()
  const [buyTicketDialogOpen, setBuyTicketDialogOpen] = useState(params.pd === 'open')

  useEffect(() => {

    if(!inProgress) {

      setInProgress(true)

      extractEventId(path)
        .then(eventId => getEvent(eventId, params))
        .then(res => {
          setInProgress(false)
          setEvent(res.event)

          if(!cookies.did) {
            setCookie('did', res.device_id, { path: '/' })
          }
        })
        .catch(err => {
          setInProgress(false)
          setError(err)
        })
    }
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setNow(new Date())
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [event])

  const handleBuyTicket = () => {
    setBuyTicketDialogOpen(true)
  }

  if(error) {
    return (<ErrorView error={error} />)
  }

  if(inProgress || !event) {
    return (<LoadingPlaceholder />)
  }

  const {
    title, description = '',
    additional_information = ''
  } = event

  const hasCredentials = (params.purchaseId && params.secret) || params.bypass

  return (
    <React.Fragment>
      <div className={classes.containerFluid}>
        {(hasCredentials || (event.free && event.state === 'public')) ? <VideoPlayer event={event} params={params} /> : <ContentPreviewWrapper event={event} params={params} />}
      </div>

      <div className={classes.mainContent}>
        <div className={classes.containerFluid}>
          <Grid container className={classes.streamingHeadContainer}>
            <Grid item xs={12}>
              <h4 className={classes.title}>{title}</h4>
            </Grid>
            <Grid item lg={12}>
              <div className={classes.gBorder}>
                <div className={classes.streamingStatus}>
                  <span>{stringifyEventStartTime(now, event.start, event.delivery_type)}</span>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container className={classes.streamingDetailsContainer}>
            <Grid item xs={12} style={{paddingTop: 16, marginBottom: 16}}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                </Grid>
                {(!hasCredentials && !event.free) && <Grid item>
                  <Button
                    variant="contained"
                    className={classes.buyTicketButton}
                    onClick={handleBuyTicket}
                  >
                    {includes(event.available_ticket_types, 'free') ? 'Get Free Ticket' : 'Buy Ticket'}
                  </Button>
                </Grid>}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 16}}>
              <p className={classes.description}>
                <ReactMarkdown plugins={[RemarkBreaks]} source={description} />
                <ReactMarkdown plugins={[RemarkBreaks]} source={additional_information} />
              </p>
              <br /><br />
              {event.url && <p className={classes.description}>Event Page: <a className={classes.eventURL} href={event.url} target="_blank" rel="noopener noreferrer">{event.url}</a></p>}
            </Grid>
          </Grid>
        </div>
      </div>
      {!hasCredentials && !event.free && buyTicketDialogOpen && <BuyTicketDialog
        open={buyTicketDialogOpen}
        params={params}
        event={event}
        onClose={() => setBuyTicketDialogOpen(false)}
      />}
    </React.Fragment>
  )
}

const App = props => {

  const classes = useStyles()

  const {path, params} = props;
  const {mode} = params

  useEffect(() => {

    request.post('/api/v1/t/post')
      .send({type: 'page', subtype: 'lander', data: params})
      .then(res => null)
      .catch(err => null)

    if(window.fbq) {
      window.fbq('track', 'ViewContent', {
        content_category: 'lander',
        content_name: 'notv'
      });
    }
  }, [])

  const embed = mode === 'embed'
  const displayNavbar = !embed
  const displayFooter = !embed

  return (
    <div className={classes.app}>
      {displayNavbar && <Navbar />}
      <EventView path={path} params={params} />
      {displayFooter && <Footer />}
    </div>
  );
}

export default compose(
  withWidth()
)(App);
