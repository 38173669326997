
import isEmpty from 'lodash/isEmpty'

import Promise from 'bluebird'

import request from 'superagent';
import React, {useEffect, useState} from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'

import CloseIcon from '@material-ui/icons/Close'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import LoadingPlaceholder from './LoadingPlaceholder'
import BuyTicketDialogContent from './BuyTicketDialogContent'

import {extractReply} from './lib/response'

const useStyles = makeStyles(theme => ({
  titleContainer: {
    marginLeft: 24,
    display: 'block',
    lineHeight: 1.2
  },
  title: {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    inlineSize: '80%'
  },
  content: {
    padding: 0,
    height: '100%',
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column'
  },
  backButton: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}));

const BuyTicketDialog = props => {

  const classes = useStyles();

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const {params, open, event, onClose} = props

  const [activeStep, setActiveStep] = useState(0)
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState()
  const [tickets, setTickets] = useState()
  const [countries, setCountries] = useState()
  const [errors, setErrors] = useState({})

  var req

  const getTickets = async (eventId) => {

    req = request.get(`/api/v1/e/${eventId}/tickets`)
      .query({pc: params.pc})
      .ok(res => true)
      .then(extractReply)

    const res = await req

    req = null

    if(res.status !== 200 || !res.body.success) {
      throw new Error(res.status === 400 ? 
        res.xhr.statusText : 'Oops, something went wrong!')
    }

    return res.body.data
  }

  const getCountries = async () => {

    const res = await request.get(`/api/v1/countries`)
      .ok(res => true)
      .then(extractReply)

    if(res.status !== 200 || !res.body.success) {
      throw new Error('Oops, something went wrong!')
    }

    return res.body.data
  }

  useEffect(() => {

    if(!inProgress) {

      setInProgress(true)

      Promise.all([
        getTickets(event._id).then(setTickets),
        getCountries().then(setCountries)
      ])
        .catch(err => {
          setError(err.message)
        })
        .finally(() => {
          setInProgress(false)
        })
    }

    return () => {
      if(req) {
        req.abort()
        req = null
      }
    }
  }, [])

  const handleClose = () => {
    onClose()
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
    setErrors({})
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="buy-ticket-dialog-title"
    >
      <DialogTitle id="buy-ticket-dialog-title">
        {activeStep !== 0 && <IconButton
          aria-label="close"
          className={classes.backButton}
          onClick={handleBack}
        >
          <KeyboardBackspaceIcon />
        </IconButton>}
        <div className={classes.titleContainer}>
          <span className={classes.title}>Buy your ticket for:</span>
          <span className={classes.title} style={{fontWeight: 'bold'}}>{event.title}</span>
        </div>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          disabled={false}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{root: classes.content}}>

        {open && <LoadingPlaceholder
          isLoading={inProgress}
          hasData={!isEmpty(tickets) && !error}
          error={error}
          noDataText="Event organizer has offered no tickets yet"
          component={() => <BuyTicketDialogContent
            params={params}
            countries={countries}
            event={event}
            tickets={tickets}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            errors={errors}
            setErrors={setErrors}
          />}
        />}

      </DialogContent>
    </Dialog>
  );
}

export default BuyTicketDialog;
