
import pick from 'lodash/pick'
import map from 'lodash/map'
import toLower from 'lodash/toLower'

import React from 'react'

import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography';

import TextField from './form/TextField'

const TicketHolderForm = props => {

  const {event, countries, values, errors, onChange, onFocus} = props

  const {
    first_name,
    last_name,
    email,
    country
  } = values;

  const available_countries = event.location_type === 'geolocked' ?
    pick(countries, map(event.countries, toLower)) : countries

  return (
    <Grid container direction="row" style={{padding: 4}}>
      <Grid item xs={12} style={{padding: 4}}>
        <Typography>Please enter ticket holder information below</Typography>
      </Grid>
      <Grid item xs={12} md={6} style={{padding: 4}}>
        <TextField
          required
          fullWidth
          variant="outlined"
          id="first-name"
          label="First Name"
          value={first_name || ''}
          error={!!errors.first_name}
          helperText={errors.first_name}
          onChange={event => onChange('first_name', event.target.value)}
          inputProps={{
            onFocus: event => onFocus('first_name')
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} style={{padding: 4}}>
        <TextField
          required
          fullWidth
          variant="outlined"
          id="last-name"
          label="Last Name"
          value={last_name || ''}
          error={!!errors.last_name}
          helperText={errors.last_name}
          onChange={event => onChange('last_name', event.target.value)}
          inputProps={{
            onFocus: event => onFocus('last_name')
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6} style={{padding: 4}}>
        <TextField
          required
          fullWidth
          variant="outlined"
          id="email"
          label="Email"
          value={email || ''}
          error={!!errors.email}
          helperText={errors.email}
          onChange={event => onChange('email', event.target.value)}
          inputProps={{
            onFocus: event => onFocus('email')
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} style={{padding: 4}}>
       <TextField
          required
          select
          fullWidth
          variant="outlined"
          label="Country"
          value={country || ''}
          error={!!errors.country}
          helperText={errors.country}
          onChange={event => onChange('country', event.target.value)}
          inputProps={{
            onFocus: event => onFocus('country')
          }}
        >
          {map(available_countries, (value, key) => (
            <MenuItem key={key} value={key}>
              {value.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )
}

export default TicketHolderForm
