
import React from 'react'

import { makeStyles } from '@material-ui/core/styles';

import ContentPreview from './ContentPreview'

const useStyles = makeStyles(theme => ({
  aspectRatioMaintenanceContainer: {
    height: 0,
    overflow: 'hidden',
    paddingTop: 'calc(min(56.25%,650px))',
    background: 'rgbs(1.0, 1.0, 1.0, 0.5)',
    position: 'relative'
  },
  videoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    maxHeight: 650,
  },
}));

const ContentPreviewWrapper = props => {

  const classes = useStyles()

  return (
    <div className={classes.aspectRatioMaintenanceContainer}>
      <div className={classes.videoContainer}>
        <ContentPreview {...props} />
      </div>
    </div>
  )
}

export default ContentPreviewWrapper;
