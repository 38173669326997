
import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    flexGrow: 1
  }
}))

const Container = props => {

  const classes = useStyles()

  const {children} = props

  return (
    <Grid container
      className={classes.container}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Grid item>
        {children}
      </Grid>
    </Grid>
  )
}

const LoadingPlaceholder = props => {
  const {
    isLoading,
    hasData,
    error,
    component,
    noDataText = 'No data available'
  } = props

  return hasData ? component() :
    <Container>
      {isLoading ? <CircularProgress size={80} style={{color: 'black'}} />
        : error ? <Typography style={{color: 'red'}}>{error}</Typography>
        : <Typography>{noDataText}</Typography>
      }
    </Container>
}

export default LoadingPlaceholder
