
import request from 'superagent';

var errors = [];
var requestInProgress = false

function doSend() {

  if(errors.length === 0 || requestInProgress) {
    return;
  }

  requestInProgress = true

  const data = errors;
  errors = []

  request.post('/api/v1/t/post')
    .send({type: 'errors', data})
    .end(function(err, response) {
      requestInProgress = false
    });
}

export function error(err) {
  errors.push(err);
  doSend();
}
