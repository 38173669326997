import isEmpty  from 'lodash/isEmpty'
import isArray  from 'lodash/isArray'
import minBy  from 'lodash/minBy'

import React, { useEffect, useState } from 'react'

import request from 'superagent';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import ImagePreview from './ImagePreview'

import { ConfigContext } from './context'
import {goodReply, extractReply} from './lib/response'

const useStyles = makeStyles(theme => ({
  video: {
    display: 'block',
    width: '100%',
    height: '100%'
  },
  imagePlaceholder: {
    width: '100%',
    height: '100%',
    maxHeight: 650,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.3)'
  }
}));

const ErrorView = props => {

  const {error} = props

  const classes = useStyles()

  return (
    <Grid container className={classes.errorContainer}>
      <Grid item>
        <Typography>{error}</Typography>
      </Grid>
    </Grid>
  )
}

const LoadingPlaceholder = () => {

  const classes = useStyles()

  return (
    <Grid container className={classes.errorContainer}>
      <Grid item>
        <CircularProgress size={80} style={{color: 'black'}} />
      </Grid>
    </Grid>
  )
}

const ContentItemPreview = props => {

  const {contentItem} = props

  if(isEmpty(contentItem.children)) {
    return (<ErrorView error='Poster is not available' />)
  }

  const scales = contentItem.children || []
  const candidateSet = [...scales]

  if(contentItem.modality === 'image') {
    candidateSet.push(contentItem)
  }

  const bestScale = minBy(candidateSet,
    scale => Math.abs(scale.metadata.resolution.height - 650))

  return (
    <ConfigContext.Consumer>
      {config => <ImagePreview contentItem={bestScale} cdnUrl={config.cdn_url} />}
    </ConfigContext.Consumer>
  )
}

const getContentItems = async (eventId, params) => {

  const res = await request.get(`/api/v1/e/${eventId}/ci`)
    .query({role: 'poster', pc: params.pc})
    .ok(goodReply)
    .then(extractReply)

  if(res.status === 404) {
    throw new Error('Event not found')
  }

  if(res.status !== 200 || !res.body.success) {
    throw new Error('Oops, something went wrong!')
  }

  return res.body.data
}

const ContentPreview = props => {

  const {event, params} = props

  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState()
  const [contentItem, setContentItem] = useState()

  useEffect(() => {
    request.post('/api/v1/t/post')
      .send({
        type: 'content',
        subtype: 'event',
        data: {id: event._id}
      })
      .then(res => null)
      .catch(err => null)
  }, [])

  useEffect(() => {

    if(!inProgress) {

      setInProgress(true)

      getContentItems(event._id, params)
        .then(res => {
          if(isEmpty(res) || !isArray(res)) {
            throw new Error('Poster is not available')
          }

          setContentItem(res[0])
        })
        .catch(err => setError(err.message))
        .finally(() => setInProgress(false))
    }

  }, [])

  return (
    <>
      {inProgress && <LoadingPlaceholder />}
      {error && <ErrorView error={error} />}
      {contentItem && <ContentItemPreview contentItem={contentItem} />}
    </>
  )
}

export default ContentPreview;
