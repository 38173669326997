
import set from 'lodash/set';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

import { ConfigContext } from './context'

import { error } from './errors';

function getParamsFromURL(url) {
  const query = url.substr(1);
  var result = {};

  query.split("&").forEach(function(part) {
    const item = part.split("=");

    if(item[0] !== '') {
      result = set(result, item[0], decodeURIComponent(item[1]));
    }
  });

  return result;
}

const params = getParamsFromURL(document.location.search)
const path = document.location.pathname
const config = window.__cld_config || {}

window.addEventListener('error', err => {
  error({
    message: err.message,
    filename: err.filename,
    lineno: err.lineno,
    colno: err.colno,
    error: err.error
  });
});

ReactDOM.render(
  <ConfigContext.Provider value={config.config || {}}>
    <App path={path} params={params} />
  </ConfigContext.Provider>,
  document.getElementById('sdroot')
);
