import delay from 'lodash/delay'

import request from 'superagent';

import React, {useState, useEffect} from 'react'

import makeStyles from '@material-ui/core/styles/makeStyles';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import RequestPermissionDialog from './RequestPermissionDialog'
import ContentPreview from './ContentPreview'

import {goodReply, extractReply} from './lib/response'

const GEO_PERMISSION_OPTIONS = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};

const useStyles = makeStyles(theme => ({
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.3)'
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: 650
  }
}))

const ErrorWidget = props => {

  const {error} = props

  const classes = useStyles()

  return (
    <Grid container className={classes.errorContainer}>
      <Grid item>
        <Typography>{error}</Typography>
      </Grid>
    </Grid>
  )
}

const LoadingPlaceholder = () => {

  const classes = useStyles()

  return (
    <Grid container className={classes.progressContainer}>
      <Grid item>
        <CircularProgress size={80} style={{color: 'white'}} />
      </Grid>
    </Grid>
  )
}

/*
const Placeholder = () => {

  const classes = useStyles()

  return (
    <Grid container className={classes.progressContainer}>
      <Grid item />
    </Grid>
  )
}
*/

const GeoPermissionRequester = props => {

  const {event, params, children} = props

  const [prompt, setPrompt] = useState(false)
  const [denied, setDenied] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [valid, setValid] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [error, setError] = useState()

  const handleStateChange = state => {
    if (state === 'granted') {
      handleProceed()
    } else if (state === 'prompt') {
      setPrompt(true)
      setDenied(false)
    } else if (state === 'denied') {
      setPrompt(false)
      setDenied(true)
    }
  }

  useEffect(() => {
    if(navigator.permissions) {
      /*
       * Query geolocation permission
       */
      navigator.permissions.query({name: 'geolocation'})
        .then(result => {
          handleStateChange(result.state)
          result.onchange = () => {
            handleStateChange(result.state)
          }
        });
    }
    else {
      /*
       * If geolocation permission object is not present,
       * then just boldly ask for geolocation
       */
      handleStateChange('prompt')
    }
  }, [])

  const handleProceed = () => {
    setPrompt(false)
    setDenied(false)

    const success = pos => {
      setDenied(false)
      setPrompt(false)

      handleValidate(pos)
    }

    const failure = err => {
      setDenied(true)
    }

    navigator.geolocation.getCurrentPosition(success, failure, GEO_PERMISSION_OPTIONS);
  }

  const handleValidate = pos => {

    setError()
    setInProgress(true)

    request.get(`/api/v1/e/${event._id.toString()}/validate-geo`)
      .query({
        lon: pos.coords.longitude,
        lat: pos.coords.latitude,
        ts: Date.now()
      })
      .ok(goodReply)
      .then(extractReply)
      .then(res => {
        setInProgress(false)

        if(res.body.inPerimeter) {
          setValid(true)
        }
        else {
          setInvalid(true)
        }
      })
      .catch(err => {
        setError('Unable to validate your geolocation')
        setInProgress(false)
      })
    
  }

  const handleNewLocation = () => {
    setInvalid(false)
    delay(handleProceed, 250)
  }

  return (
    <React.Fragment>
      <RequestPermissionDialog
        open={prompt}
        title="We need access to your location!"
        subtitle='This audio/video is geofenced and can only be enjoyed if you are on the right location. When you click "PROCEED" we will therefore request your location from your device. Enjoy!'
        onProceed={handleProceed}
      />
      <RequestPermissionDialog
        open={denied}
        title="Unable to read your geolocation!"
        subtitle={
          <React.Fragment>
            We are unable to read your geolocation or you denied us to do it. NOTV needs your location, such that we can validate that you are within the event perimeter. Enable geolocation for this website and click "Retry".<br/>
            To enable geolocation make sure:
            <ul>
              <li>iOS: Go to Settings &gt; Privacy &gt; Location Services &gt; ON</li>
              <li>Android: Go to Settings &gt; Location &gt; ON</li>
            </ul>
          </React.Fragment>
        }
        buttontitle="Retry"
        onProceed={handleProceed}
      />
      <RequestPermissionDialog
        open={invalid}
        title="Oops, wrong place!"
        subtitle={"This content is 'geofenced' and we detected that you are not yet within this event's perimeter! Please advance to the right location and click \"RETRY\" or REFRESH this page to enjoy this audio/video!"}
        buttontitle="Retry"
        onProceed={handleNewLocation}
      />
      {error ? <ErrorWidget error={error} />
        : valid ? children : inProgress ? <LoadingPlaceholder /> : <ContentPreview params={params} event={event} />}
    </React.Fragment>
  )
}

export default GeoPermissionRequester
