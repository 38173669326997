
import map from 'lodash/map'
import times from 'lodash/times'

import React, {useEffect} from 'react'

import makeStyles from '@material-ui/core/styles/makeStyles';

import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import {formatCurrency} from './currency'

const useStyles = makeStyles(theme => ({
  list: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: 100,
      marginRight: 100
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 60,
      marginRight: 60
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 60,
      marginRight: 60
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 24,
      marginRight: 24
    },
    paddingTop: 16,
    paddingBottom: 16,
  },
  listItem: {
    borderBottom: '1px solid rgb(200, 200, 200)',
    display: 'block',
    paddingTop: 16,
    paddingBottom: 16,
  }
}))

const TicketListItem = props => {

  const classes = useStyles()

  const {ticket, selectedQuantity, onChange} = props

  useEffect(() => {
    onChange(1)
  }, [])

  const soldout = ticket.soldout || ticket.available === 0
  const maxQuantity = 1

  return (
    <li className={classes.listItem}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs style={{marginBottom: 8}}>
            <Typography variant="h6" style={{color: "black"}}>{ticket.title}</Typography>
            <Typography variant="caption" style={{color: "black"}}>{ticket.price > 0 ? formatCurrency(ticket.price) : 'Free'}</Typography>
          </Grid>
          <Grid item>
            <TextField
              id="select-quanitity"
              variant="outlined"
              select
              value={selectedQuantity}
              disabled={soldout}
              onChange={event => onChange(event.target.value)}
            >
              {times(maxQuantity, n => (
                <MenuItem key={n} value={n + 1}>
                  {n + 1}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {ticket.live || <Typography variant="body2">Sale has not started yet</Typography>}
        {ticket.live && <Typography variant="body2">{ticket.description || ''}</Typography>}
      </Grid>
    </li>
  )
}

const TicketList = props => {

  const classes = useStyles()

  const {tickets, order, onChange} = props

  return (
    <List className={classes.list}>
      {map(tickets, ticket => (
        <TicketListItem
          key={ticket._id}
          ticket={ticket}
          selectedQuantity={order[ticket._id] || 0}
          onChange={newQuantity => onChange(ticket._id, newQuantity)}
        />
      ))}
    </List>
  )
}

export default TicketList
