import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import logo from './assets/images/notv-logo.png';

const useStyles = makeStyles(theme => ({
  '@global': {
    a: {
      textDecoration: 'none'
    },
    small: {
      fontWeight: 400,
      fontSize: 14
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
      margin: 0,
      lineHeight: '1.2',
      color: 'white'
    }
  },
  footer: {
    display: 'inline-block',
    width: '100%',
    float: 'left',
    backgroundRepeat: 'no-repeat !important',
    backgroundSize: 'cover !important',
    background: '#191919',
    overflow: 'hidden'
  },
  footerTop: {
    paddingTop: 60,
    paddingBottom: 60
  },
  containerFluid: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',

    [theme.breakpoints.up('md')]: {
      paddingLeft: 100,
      paddingRight: 100
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 30,
      paddingRight: 30
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 15,
      paddingRight: 15
    },
    boxSizing: 'border-box'
  },
  menu: {
    padding: 0,
    marginTop: 0
  },
  menuItem: {
    display: 'inline-block',
    paddingRight: 30,

    '& a': {
      border: 'none',
      marginBottom: 4,
      color: '#D1d0CF'
    }
  },
  textWidget: {
    '& p': {
      marginBottom: 0,
      marginBlockStart: 0
    },
    '& a': {
      alignItems: 'center',
      justifyContent: 'center',
      width: 40,
      height: 40,
      textAlign: 'center',
      marginRight: 0,
      color: 'white',
      fontSize: 17,
      transition: 'all 0.4s ease 0s',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
  },
  inlineLogo: {
    width: 32,
    height: 32,
    margin: 5
  },
  footerLinkTitle: {
    marginBottom: 20,
    marginTop: 0
  },
  infoShare: {
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0,

    '& li': {
      marginLeft: 15,
      marginRight: 12,
      display: 'inline-block',
    },
    '& a': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 40,
      height: 40,
      textAlign: 'center',
      marginRight: 0,
      color: 'white',
      fontSize: 17,
      borderRadius: '50%',
      background: 'rgba(41, 41, 41, 0.76)',
      transition: 'all 0.4s ease 0s'
    }
  }
}));

const Footer = props => {

  const classes = useStyles()

  return (
    <footer className={classes.footer}>
       
      <div className={classes.footerTop}>
        <div className={classes.containerFluid}>
          <Grid container>
            <Grid item lg={7}>
              
              <div className="widget text-left">			
                <div className={classes.textWidget}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <a href="https://notv.com" target="_blank" rel="noopener noreferrer"><img src={logo} className={classes.inlineLogo} alt="notv" /></a>
                    <p>Powered by <a href="https://notv.com" target="_blank" rel="noopener noreferrer">NOTV</a></p>
                  </div>
                </div>
                <div className={classes.textWidget}>
                  <p><small>© 2022 NOTV B.V. All Rights Reserved. All content on this platform are copyright to their respective owners. Duplication and copying is strictly prohibited.</small></p>
                </div>
              </div>                        
            </Grid>
            <Grid item lg={2} md={6} >
              <ul className={classes.infoShare}> 
                <li>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/NOTV-141858685850839">
                    <FacebookIcon />
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/notv_com/">
                    <InstagramIcon />
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/notv/">
                    <LinkedInIcon />
                  </a>
                </li>
              </ul>
            </Grid>
            <Grid item lg={7}>
              <div className="widget text-left">
                <div className="menu-footer-link-1-container">
                  <ul className={classes.menu}>
                    <li className={classes.menuItem}>
                      <a href="https://notv.com/about/" target="_blank" rel="noopener noreferrer">About</a>
                    </li>
                    <li className={classes.menuItem}>
                      <a href="https://notv.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                    </li>
                    <li className={classes.menuItem}>
                      <a href="https://notv.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </footer>
  );
}

export default Footer
