
/*
 * Test environments list
 */
export const TEST_ENVS = ['test', 'gitlab-ci'];
export const LIVE_ENVS = ['production'];
export const MOCK_ENVS = ['development', 'testing', 'test', 'gitlab-ci'];

/*
 * Time intervals
 */
export const HR_DAY = 24; // hours
export const MN_HOUR = 60; // minutes
export const SC_MINUTE = 60; // seconds
export const MS_SECOND = 1000; // milliseconds
export const MS_MINUTE = SC_MINUTE * MS_SECOND;
export const MS_HOUR = MN_HOUR * MS_MINUTE;
export const MS_DAY = HR_DAY * MS_HOUR;

export const OBJECT_ID_REGEXP = new RegExp('^[0-9a-fA-F]{24}$');

export const TICKET_REGEXP = new RegExp('^[0-9]+$', 'i');

export const API_V1_PREFIX = '/api/v1'

export const TOKEN_COOKIE = 'u'
