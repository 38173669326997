
import omit  from 'lodash/omit'
import isEmpty  from 'lodash/isEmpty'

import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';

import request from 'superagent';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import GeoPermissionRequester from './GeoPermissionRequester'
import Video from './Video'

import {goodReply, extractReply} from './lib/response'

const useStyles = makeStyles(theme => ({
  aspectRatioMaintenanceContainer: {
    height: 0,
    overflow: 'hidden',
    paddingTop: 'calc(min(56.25%,650px))',
    background: 'rgbs(1.0, 1.0, 1.0, 0.5)',
    position: 'relative'
  },
  videoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    maxHeight: 650,
  },
  video: {
    display: 'block',
    width: '100%',
    height: '100%'
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.3)'
  }
}));

const Error = props => {

  const {error} = props

  const classes = useStyles()

  return (
    <Grid container className={classes.errorContainer}>
      <Grid item>
        <Typography>{error}</Typography>
      </Grid>
    </Grid>
  )
}

const LoadingPlaceholder = () => {

  const classes = useStyles()

  return (
    <Grid container className={classes.errorContainer}>
      <Grid item>
        <CircularProgress size={80} style={{color: 'black'}} />
      </Grid>
    </Grid>
  )
}

const VideoContent = props => {

  const {event, params, meta} = props;

  const hasPerimeter = event.location_type === 'geofenced' && !isEmpty(event.perimeter)

  if(hasPerimeter) {
    return (
      <GeoPermissionRequester params={params} event={event}>
        <Video meta={meta} />
      </GeoPermissionRequester>
    )
  }

  return (
    <Video meta={meta} />
  )
}

const VideoPlayer = props => {

  const {event, params} = props;

  const classes = useStyles()

  const [cookies] = useCookies(['did']);

  const [inProgress, setInProgress] = useState(true)
  const [error, setError] = useState()
  const [meta, setMeta] = useState()

  useEffect(() => {

    request.post('/api/v1/t/post')
      .send({type: 'content', subtype: 'event', data: {id: event._id.toString()}})
      .then(res => null)
      .catch(err => null)

    if(params.bypass) {
      setInProgress(false)
      setMeta({})
      return;
    }

    request.get(`/api/v1/e/${event._id.toString()}/validate-ticket`)
      .query({
        pc: params.pc,
        purchase_id: params.purchaseId,
        secret: params.secret,
        did: cookies.did,
        ncts: Date.now()
      })
      .ok(goodReply)
      .then(extractReply)
      .then(res => {
        setInProgress(false)
        setMeta(omit(res.body, ['success']))
      })
      .catch(err => {
        setInProgress(false)
        setError(err.message)
      })

  }, [])

  return (
    <div className={classes.aspectRatioMaintenanceContainer}>
      <div className={classes.videoContainer}>
        {inProgress && <LoadingPlaceholder />}
        {meta && <VideoContent meta={meta} params={params} event={event} />}
        {error && <Error error={error} />}
      </div>
    </div>
  )
}

export default VideoPlayer;
