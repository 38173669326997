
import querystring from 'querystring'

import React, {useRef, useEffect, useState} from 'react'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  video: {
    display: 'block',
    width: '100%',
    height: '100%'
  }
}));

const HTMLVideo = props => {

  const {meta} = props

  const classes = useStyles()

  const qs = querystring.stringify(meta)

  return (
    <video className={classes.video} controls autoPlay controlsList="nodownload">
      <source src={`//rrr.sz.xlcdn.com/?${qs}`} type="video/mp4" />
    </video>
  )
}

const JetStreamVideo = props => {

  const {meta} = props

  const videoEl = useRef(null);
  const [, setPlayer]= useState()

  useEffect(() => {
    const qs = querystring.stringify(meta)
    const p = new document.PrivacyPlayer(
      `//player.jetstre.am/?${qs}`, 
      videoEl.current, true, true 
    );

    setPlayer(p)

    return () => {
      setPlayer(null)
    }
  }, [])

  return (
    <div ref={videoEl} style={{height: '100%', width: '100%'}} />
  )
}

const Video = props => {

  const {meta} = props

  return meta.type === 'download' ? <HTMLVideo {...props} />
    : <JetStreamVideo {...props} />
}

export default Video
