
import numeral from 'numeral';

export const formatCurrency = (value) => {
  if(!value) {
    return '-';
  }

  const currency_symbol = '€ '
  const format = '0,0.00'
  return currency_symbol + numeral(value).format('(' + format + ')')
}
