
import moment from 'moment';

export const stringifyEventStartTime = (_now, ts, delivery_type) => {
  const date = moment(ts)
  const now = moment(_now)

  const prefix = delivery_type === 'vod' ? 'Available from' : now.isBefore(date) ? 'Starts' : 'Started'

  if(now.dayOfYear() === date.dayOfYear() && delivery_type !== 'vod') {

    if(now.isBefore(date)) {
      const duration = moment.duration(date.diff(now))

      if(duration.hours() === 0) {

        if(duration.minutes() >= 15) {
          return prefix + ' in ' + duration.minutes() + ' minutes'
        }

        return prefix + ' in ' + moment.utc(duration.as('milliseconds')).format('mm:ss')
      }

      return prefix + ' at ' + date.format('HH:mm')
    }
    else {
      const duration = moment.duration(now.diff(date))

      if(duration.hours() === 0) {

        if(duration.minutes() >= 15) {
          return prefix + ' ' + duration.minutes() + ' minutes ago'
        }

        if(duration.seconds() !== 0) {
          return prefix + ' ' + moment.utc(duration.as('milliseconds')).format('mm:ss') + ' ago'
        }

        return prefix + ' just now'
      }

      return prefix + ' at ' + date.format('HH:mm')
    }
  }

  const tomorrow = moment(now).add(1, 'days')
  const yesterday = moment(now).subtract(1, 'days')

  if(date.dayOfYear() === tomorrow.dayOfYear()) {
    return prefix + ' tomorrow at ' + date.format('HH:mm')
  }

  if(date.dayOfYear() === yesterday.dayOfYear()) {
    return prefix + ' yesterday at ' + date.format('HH:mm')
  }

  return prefix + (delivery_type !== 'vod' ? ' on ' : ' ') + ((now.year() === date.year()) ?
    date.format('ddd, MMM D') : date.format('ll'))
}
